import { FC, useEffect, useState } from 'react';
import Item from './Item';
import { useAppSelector } from '../../hooks/redux';
import AppDownloadModal from '../Modals/AppDownloadModal.tsx';
import TrustLessonWarning from '../TrustLessonWarning/TrustLessonWarning.tsx';

const FAQAdaptive: FC = () => {
  const [isAppDownloadModalOpened, setIsAppDownloadModalOpened] =
    useState<boolean>(false);
  const { faq } = useAppSelector((state) => state.FaqSlice);
  const user = useAppSelector((state) => state.UserSlice.user);

  useEffect(() => {
    function checkFCM() {
      setIsAppDownloadModalOpened(true);
    }
    !user.fcm &&
      user.role !== 'DIRECTOR' &&
      user.role !== 'ZDIRECTOR' &&
      user.role !== 'ADMIN' &&
      checkFCM();
  }, []);
  return (
    <div>
      <TrustLessonWarning />
      <div className="w-full max-h-full flex flex-col items-center bg-[#f0f0f0] rounded-xl overflow-auto p-2">
        <p className="text-red-500 mb-5 text-lg font-bold">
          Советы редактируются и идеализируются. Также будем добавлять новые
          разные темы до 01.06.2024.
        </p>
        {faq && faq.map((item) => <Item key={item._id} item={item}></Item>)}
        <AppDownloadModal
          active={isAppDownloadModalOpened}
          onClose={setIsAppDownloadModalOpened}
        />
      </div>
    </div>
  );
};

export default FAQAdaptive;
