/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { FC, useEffect, useState } from 'react';
import Title from '../../UI/Title';
import { IMaterial } from '../../../models/Program/IMaterial';
import Item from './Item';
import { GroupChangeMaterialSocket } from '../../../sockets/GroupSockets';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../hooks/redux';
import {
  ITranslateItemString,
  translations,
} from '../../../utils/translations.tsx';
import CommentFirstModal from './Modals/CommentFirstModal.tsx';
import CommentsService from '../../../services/CommentsService.ts';
import { IComment } from '../../../models/response/IComment.ts';
import { AxiosResponse } from 'axios';

interface ProgramProps {
  program: IMaterial[];
  setMaterial: (material: IMaterial) => void;
  setMaterialName?: (name: string) => void;
  active?: string;
  homework?: boolean;
  commentCount?: number;
  setCommentCount?: (count: number) => void;
}

const Program: FC<ProgramProps> = ({
  program,
  setMaterial,
  setMaterialName,
  active,
  setCommentCount,
  homework,
}) => {
  const { groupId } = useParams();
  const { user } = useAppSelector((state) => state.UserSlice);
  const language = useAppSelector((state) => state.TranslateSlice.language);
  const [firstCommentModal, setFirstCommentModal] = useState(false);
  const [currentChapter, setCurrentChapter] = useState<IMaterial>();
  const {
    chapterText,
  }: {
    chapterText: ITranslateItemString;
  } = translations.lessons;
  const openModalWindow = (): void => setFirstCommentModal(true);

  const setMaterialHandler = (item: IMaterial) => {
    setCurrentChapter(item);
    if (!homework) {
      if (
        (user.role === 'DIRECTOR' ||
          user.role === 'ZDIRECTOR' ||
          user.role === 'TRANER') &&
        groupId
      ) {
        setMaterial(item);
        GroupChangeMaterialSocket({ room: groupId, material: item });
      }
    } else {
      setMaterial(item);
      if (setMaterialName) {
        setMaterialName(item.theme_id.name);
      }
    }
  };

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const response: AxiosResponse<IComment[]> =
          await CommentsService.getCommentByGroup(currentChapter?._id || '');
        if (Array.isArray(response.data)) {
          const filteredComments: IComment[] = response.data.filter(
            (comment) =>
              comment.status === 'APPROVED' || comment.status === 'PAID',
          );
          setCommentCount?.(filteredComments.length);
        } else {
          console.error('Ответ не является массивом комментариев:', response);
        }
      } catch (error) {
        console.error('Не удалось получить комментарии:', error);
      }
    };

    if (currentChapter) {
      void fetchComments();
    }
  }, [currentChapter, setCommentCount]);

  return (
    <div className="flex flex-col h-full">
      <div className="flex mt-1  pb-3">
        <Title name={chapterText[language]} className={'py-[10px] w-full'} />
        {/* <MainButton
          className="bg-gray-100 border rounded-2xl text-gray-800 px-3 py-2 flex"
          onClick={openModalWindow}
          disabled={!currentChapter} // Отключить кнопку, если currentChapter пустой
        >
          <p className="mr-2"> Комментировать </p>
          <p>{commentCount}</p>
        </MainButton> */}
      </div>

      <div className="border-2 border-[#CCC] -mt-6 pt-6 rounded-b-2xl border-t-0 h-[calc(100%-30px)]">
        <div className={['flex flex-col overflow-auto h-full'].join(' ')}>
          {program.length > 0 &&
            program.map((item, id) => (
              <>
                <Item
                  active={active}
                  onClick={() => setMaterialHandler(item)}
                  key={item._id}
                  id={item._id}
                  number={id + 1}
                  taskName={item.data.tags.Event}
                />
                <div className="">{}</div>
                <CommentFirstModal
                  openModal={firstCommentModal}
                  setOpenModal={setFirstCommentModal}
                  groupId={currentChapter?._id || ''}
                  type={'CHAPTER'}
                />
              </>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Program;
