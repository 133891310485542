import { AxiosResponse } from 'axios';
import $api from '../http';
import {
  IEditInfoText,
  IGetInfoText,
} from '../models/response/InfoTextsResponses';

export default class InfoService {
  static async getInfoText(field: string): Promise<AxiosResponse<IGetInfoText>> {
    return $api.get<IGetInfoText>(`/service1/infotexts/getInfoText?field=${field}`);
  }

  static async editInfoText(
    payload?: IEditInfoText,
  ): Promise<AxiosResponse<IEditInfoText>> {
    return $api.put<IEditInfoText>('/service1/infotexts/editInfoText', payload, {
      withCredentials: true,
    });
  }
}