import { Elanguages } from "../store/reducers/TranslateSlice";

export interface IInfoText {
    [Elanguages.RU]: string | undefined;
    [Elanguages.EN]: string | undefined;
    [Elanguages.AM]: string | undefined;
}

export enum EInfoTextFields {
  teamsGameInfo = 'teamsGameInfo',
  scheduleInfo = 'scheduleInfo',
}