// Цвет сеансёра в каждой партии
export const seanserColors = [
  {
    id: '1',
    name: 'Белый',
    slug: 'white',
  },
  {
    id: '2',
    name: 'Рандом',
    slug: 'random',
  },
  {
    id: '3',
    name: 'Чёрный',
    slug: 'black',
  },
];

// Начальное время на часах
export const startTime = [
  {
    id: '1',
    name: '5 минут',
    slug: "300000",
  },
  {
    id: '2',
    name: '10 минут',
    slug: '600000',
  },
  {
    id: '3',
    name: '15 минут',
    slug: '900000',
  },
  {
    id: '4',
    name: '20 минут',
    slug: '1200000',
  },
  {
    id: '5',
    name: '30 минут',
    slug: '1800000',
  },
  {
    id: '6',
    name: '40 минут',
    slug: '2400000',
  },
  {
    id: '7',
    name: '50 минут',
    slug: '3000000',
  },
  {
    id: '8',
    name: '60 минут',
    slug: '3600000',
  },
  {
    id: '9',
    name: '70 минут',
    slug: '4200000',
  },
  {
    id: '10',
    name: '80 минут',
    slug: '4800000',
  },
  {
    id: '11',
    name: '90 минут',
    slug: '5400000',
  },
  {
    id: '12',
    name: '120 минут',
    slug: '7200000',
  },
  {
    id: '13',
    name: '140 минут',
    slug: '8400000',
  },
  {
    id: '14',
    name: '160 минут',
    slug: '9600000',
  },
  {
    id: '15',
    name: '180 минут',
    slug: '10800000',
  },
];

// Добавка времени
export const additionalTime = [
  {
    id: '1',
    name: '0 секунд',
    slug: '0',
  },
  {
    id: '2',
    name: '1 секунд',
    slug: '1000',
  },
  {
    id: '3',
    name: '2 секунд',
    slug: '2000',
  },
  {
    id: '4',
    name: '3 секунд',
    slug: '3000',
  },
  {
    id: '5',
    name: '4 секунд',
    slug: '4000',
  },
  {
    id: '6',
    name: '5 секунд',
    slug: '5000',
  },
  {
    id: '7',
    name: '6 секунд',
    slug: '6000',
  },
  {
    id: '8',
    name: '7 секунд',
    slug: '7000',
  },
  {
    id: '9',
    name: '10 секунд',
    slug: '10000',
  },
  {
    id: '10',
    name: '15 секунд',
    slug: '15000',
  },
  {
    id: '11',
    name: '20 секунд',
    slug: '20000',
  },
  {
    id: '12',
    name: '25 секунд',
    slug: '25000',
  },
  {
    id: '13',
    name: '30 секунд',
    slug: '30000',
  },
  {
    id: '14',
    name: '40 секунд',
    slug: '40000',
  },
  {
    id: '15',
    name: '50 секунд',
    slug: '50000',
  },
  {
    id: '16',
    name: '60 секунд',
    slug: '60000',
  },
  {
    id: '17',
    name: '90 секунд',
    slug: '90000',
  },
  {
    id: '18',
    name: '120 секунд',
    slug: '120000',
  },
  {
    id: '19',
    name: '150 секунд',
    slug: '150000',
  },
  {
    id: '20',
    name: '180 секунд',
    slug: '180000',
  },
];

// Дополнительное время сеансёра
export const extraTime = [
  {
    id: '1',
    name: '-120 минут',
    slug: '-7200000',
  },
  {
    id: '2',
    name: '-90 минут',
    slug: '-5400000',
  },
  {
    id: '3',
    name: '-60 минут',
    slug: '-3600000',
  },
  {
    id: '4',
    name: '-50 минут',
    slug: '-3000000',
  },
  {
    id: '5',
    name: '-40 минут',
    slug: '-2400000',
  },
  {
    id: '6',
    name: '-30 минут',
    slug: '-1800000',
  },
  {
    id: '7',
    name: '-20 минут',
    slug: '-1200000',
  },
  {
    id: '8',
    name: '-15 минут',
    slug: '-900000',
  },
  {
    id: '9',
    name: '-10 минут',
    slug: '-600000',
  },
  {
    id: '10',
    name: '-5 минут',
    slug: '-300000',
  },
  {
    id: '11',
    name: '0 минут',
    slug: '0min',
  },
  {
    id: '12',
    name: '5 минут',
    slug: '300000',
  },
  {
    id: '13',
    name: '10 минут',
    slug: '600000',
  },
  {
    id: '14',
    name: '15 минут',
    slug: '900000',
  },
  {
    id: '15',
    name: '20 минут',
    slug: '1200000',
  },
  {
    id: '16',
    name: '30 минут',
    slug: '1800000',
  },
  {
    id: '17',
    name: '40 минут',
    slug: '2400000',
  },
  {
    id: '18',
    name: '50 минут',
    slug: '3000000',
  },
  {
    id: '19',
    name: '60 минут',
    slug: '3600000',
  },
  {
    id: '20',
    name: '90 минут',
    slug: '5400000',
  },
];

// Добавка времени сеансёра для каждого игрока
export const additionalTimeSeanserForPlayers = [
  {
    id: '1',
    name: '0 секунд',
    slug: '0',
  },
  {
    id: '2',
    name: '10 секунд',
    slug: '10000',
  },
  {
    id: '3',
    name: '20 секунд',
    slug: '20000',
  },
  {
    id: '4',
    name: '30 секунд',
    slug: '30000',
  },
  {
    id: '5',
    name: '40 секунд',
    slug: '40000',
  },
  {
    id: '6',
    name: '50 секунд',
    slug: '50000',
  },
  {
    id: '7',
    name: '60 секунд',
    slug: '60000',
  },
  {
    id: '8',
    name: '90 секунд',
    slug: '90000',
  },
  {
    id: '9',
    name: '120 секунд',
    slug: '120000',
  },
  {
    id: '10',
    name: '180 секунд',
    slug: '180000',
  },
  {
    id: '11',
    name: '240 секунд',
    slug: '240000',
  },
  {
    id: '12',
    name: '300 секунд',
    slug: '300000',
  },
];

export const visibility = [
  {
    id: '1',
    name: 'Для учеников школы',
    slug: 'private',
  },
  {
    id: '2',
    name: 'Публичный сеанс',
    slug: 'public',
  },
]

export const ratingInterval = [
  {
    id: '1',
    name: '800-1200',
    slug: '800-1200',
  },
  {
    id: '2',
    name: '900-1300',
    slug: '900-1300',
  },
  {
    id: '3',
    name: '1000-1400',
    slug: '1000-1400',
  },
  {
    id: '4',
    name: '1100-1500',
    slug: '1100-1500',
  },
  {
    id: '5',
    name: '900-1300',
    slug: '900-1300',
  },
  {
    id: '6',
    name: '1200-1600',
    slug: '1200-1600',
  },
  {
    id: '6',
    name: '1300+',
    slug: '1300+',
  },
]


// Время до начала сеанса
export const timeBeforeStart = [
  {
    id: '1',
    name: '2 минут',
    slug: '120000',
  },
  {
    id: '2',
    name: '5 минут',
    slug: '300000',
  },
  {
    id: '3',
    name: '10 минут',
    slug: '600000',
  },
  {
    id: '4',
    name: '15 минут',
    slug: '900000',
  },
  {
    id: '5',
    name: '20 минут',
    slug: '1200000',
  },
  {
    id: '6',
    name: '25 минут',
    slug: '1500000',
  },
  {
    id: '7',
    name: '30 минут',
    slug: '1800000',
  },
];
