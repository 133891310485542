import { FC, useState } from "react";
import Modal from "../UI/Modal";
import ChangeLanguageBtns from "../UI/ChangeLanguageBtns";
import { Elanguages } from "../../store/reducers/TranslateSlice";
import MDEditor from '@uiw/react-md-editor';
import Button from "../UI/Button";
import { IInfoText } from "../../models/IInfoTexts";
import { translations } from "../../utils/translations";

interface EditTeamsGameInfoModalProps {
  text: IInfoText;
  setText: (text: IInfoText) => void;
  active: boolean;
  setActive: (active: boolean) => void;
  onEditText: (text: IInfoText) => Promise<void>;
}

const {
  editText
} = translations.lessons

const EditTeamsGameInfoModal:FC<EditTeamsGameInfoModalProps> = ({ active, setActive, text, setText, onEditText }) => {
  const [language, setLanguage] = useState<Elanguages>(Elanguages.RU);

  const onEditTextHandler = () => {
    void onEditText(text);
  }

  return (
    <>
    <Modal 
      active={active}
      setActive={setActive}
      closeBtnStyle={'!text-black'}
      className={`!max-w-[1100px] p-8 !h-[800px] mh-full !w-full`}
    >
      <MDEditor
        value={text[language]}
        onChange={(t) => setText({...text, [language]: t})}
        style={{listStyle: 'revert'}}
        height={650}
      />
      <ChangeLanguageBtns className="mt-2" language={language} onLangChange={setLanguage}/>
      <Button
        className="mt-2"
        onClick={onEditTextHandler}
      >
        {editText[language]}
      </Button>
    </Modal>
    </>
  );
}

export default EditTeamsGameInfoModal;