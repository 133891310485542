import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ServerError } from '../../models/response/ServerError';
import InfoTextsService from '../../services/InfoTextsService';
import { IInfoText, EInfoTextFields } from '../../models/IInfoTexts';

export const getInfoText = createAsyncThunk<
  { text: IInfoText; field: EInfoTextFields },
  { field: EInfoTextFields }
>(
  'infoTextsSlice/getInfoText',
  async ({ field }, { rejectWithValue }) => {
    try {
      const response = await InfoTextsService.getInfoText(field);
      return { text: response.data.text, field };
    } catch (error) {
      const err = error as AxiosError;
      const e = err.response?.data as ServerError;
      return rejectWithValue(e);
    }
  }
);

export const editInfoText = createAsyncThunk<
  { text: IInfoText; field: EInfoTextFields },
  { text: IInfoText; field: EInfoTextFields }
>(
  'infoTextsSlice/editInfoText',
  async ({ text, field }, { rejectWithValue }) => {
    try {
      const response = await InfoTextsService.editInfoText({ text, field });
      return { text: response.data.text, field };
    } catch (error) {
      const err = error as AxiosError;
      const e = err.response?.data as ServerError;
      return rejectWithValue(e);
    }
  }
);

const initialState: {
  [EInfoTextFields.teamsGameInfo]: IInfoText;
  [EInfoTextFields.scheduleInfo]: IInfoText;
} = {
  [EInfoTextFields.teamsGameInfo]: {
    ru: '',
    en: '',
    am: '',
  },
  [EInfoTextFields.scheduleInfo]: {
    ru: '',
    en: '',
    am: '',
  },
};

const infoTextsSlice = createSlice({
  name: 'infoTextsSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getInfoText.fulfilled, (state, action) => {
        state[action.payload.field] = action.payload.text;
      })
      .addCase(editInfoText.fulfilled, (state, action) => {
        state[action.payload.field] = action.payload.text;
      });
  },
});

export default infoTextsSlice.reducer;