import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { GroupUpdateSocket } from '../../sockets/GroupSockets';
import { getGroup, getTestGroup } from '../../store/reducers/GroupSlice';
import { getThemes } from '../../store/reducers/ProgramSlice';
import {
  ITranslateItemString,
  translations,
} from '../../utils/translations.tsx';
import Filter from '../Program/Filter';
import Materials from '../Program/Materials';
import Themes from '../Program/Themes';
import MainButton from '../UI/MainButton';
import Modal from '../UI/Modal';

import './ProgramModal.css';

interface ProgramModalProps {
  modal: boolean;
  setModal: (bool: boolean) => void;
  testlesson?: boolean;
}

const ProgramModal: FC<ProgramModalProps> = ({
  modal,
  setModal,
  testlesson,
}) => {
  const dispatch = useAppDispatch();
  const { groupId } = useParams();
  const language = useAppSelector((state) => state.TranslateSlice.language);
  const [changeOrder, setChangeOrder] = useState<boolean>(true);

  const {
    goBackToLessonText,
  }: {
    goBackToLessonText: ITranslateItemString;
  } = translations.lessons;

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getThemes({}));
    };
    void fetchData();
  }, [dispatch]);

  const closeHandler = async () => {
    if (groupId) {
      if (testlesson) {
        await dispatch(getTestGroup(groupId));
      } else {
        await dispatch(getGroup(groupId));
      }
      GroupUpdateSocket({ room: groupId });
    }
  };

  return (
    <Modal
      onClose={() => void closeHandler()}
      active={modal}
      setActive={setModal}
      className="!max-w-[1500px] max-2xl:!max-w-[1300px] p-0 items-center h-full programModal"
    >
      <div className="flex flex-col bg-[#F0F0F0] rounded-3xl w-full h-full overflow-auto">
        <Filter setChangeOrder={setChangeOrder} />
        <div className="p-2">
          <MainButton
            onClick={() => {
              setModal(false);
              void closeHandler();
            }}
            className="mb-5"
          >
            {goBackToLessonText[language]}
          </MainButton>
        </div>
        <div className="flex justify-between px-5 py-3 h-full gap-5 overflow-auto min-h-[400px]">
          <Themes changeOrder={changeOrder} />
          <Materials testlesson={testlesson} setActive={setModal} />
        </div>
      </div>
    </Modal>
  );
};

export default ProgramModal;
