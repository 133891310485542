import { Menu } from '@headlessui/react';
import { IoIosArrowDown } from '@react-icons/all-files/io/IoIosArrowDown';
import { Reorder, useDragControls, useInView } from 'framer-motion';
import { RefObject, forwardRef, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { ITheme } from '../../models/Program/ITheme';
import ProgramService from '../../services/ProgramService';
import {
  getMaterials,
  getThemes,
  setTheme,
  updateThemeOrders,
} from '../../store/reducers/ProgramSlice';

interface ThemeProps {
  theme: ITheme;
  active: string;
  setActive: (value: string) => void;
  editHandler: (theme: ITheme) => void;
  removeHandler: (theme: ITheme) => void;
  changeOrder: boolean | undefined;
}

const Theme = forwardRef<HTMLDivElement, ThemeProps>(
  (
    { theme, active, setActive, editHandler, removeHandler, changeOrder },
    ref,
  ) => {
    const dispatch = useAppDispatch();
    const controls = useDragControls();
    const { user } = useAppSelector((state) => state.UserSlice);
    const { themes } = useAppSelector((state) => state.ProgramSlice);
    const numberList = +`${themes[0].level}00` + themes.indexOf(theme) + 1;
    const [isPressed, setIsPressed] = useState<boolean>(false);
    const [shouldOpenUp, setShouldOpenUp] = useState<boolean>(false);
    const param = useParams();
    const isParams = !param.groupId;
    const themeRef = useRef<HTMLDivElement>(null);
    const isInView = useInView(themeRef, {
      root: ref as RefObject<HTMLDivElement>,
    });

    // TODO: Разообраться с багами комментариев! Пока отключено by den
    // const [firstCommentModal, setFirstCommentModal] = useState(false);
    // const [commentCount, setCommentCount] = useState();

    const fetchMaterials = async () => {
      setActive(theme._id);
      dispatch(setTheme(theme._id));
      await dispatch(getMaterials(theme._id));
    };

    // const openFirstModal = (): void => setFirstCommentModal(true);

    const DragEndHandler = async () => {
      const updatedThemes = themes.map((t: ITheme, index: number) => ({
        ...t,
        order: index + 1,
      }));

      try {
        changeOrder &&
          (await dispatch(updateThemeOrders({ themes: updatedThemes })));
      } catch (error) {
        console.error('Error updating theme orders:', error);
      }
    };

    const toggleLine = async (type: string, button: number) => {
      if (!isParams) {
        return;
      }

      if (type === 'click' && button === 0) {
        if (!isPressed) {
          setIsPressed(true);
          setTimeout(() => {
            setIsPressed(false);
          }, 300);
        } else {
          const line = theme.line === 'yas' ? 'no' : 'yas';
          await ProgramService.editTheme(theme._id, line);
          await dispatch(getThemes({ level: theme.level }));
        }
      }
    };

    useEffect(() => {
      const handleScroll = () => {
        if (themeRef.current) {
          const el = themeRef.current;
          const rect = el.getBoundingClientRect();
          const windowHeight = window.innerHeight;
          const distanceFromBottom = windowHeight - rect.bottom;
          setShouldOpenUp(distanceFromBottom < 80);
        }
      };

      if (isInView) {
        window.addEventListener('scroll', handleScroll);
        handleScroll();
      }
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [isInView]);

    return (
      <Reorder.Item
        dragListener={isParams}
        dragControls={
          user.role === 'DIRECTOR' || user.role === 'ZDIRECTOR'
            ? controls
            : undefined
        }
        key={theme._id}
        value={theme}
        onDragEnd={() => void DragEndHandler()}
        transition={{ duration: 0.01 }}
      >
        <div
          className={[
            'flex items-center w-full  mb-2 relative [&>.arr-menu]:hover:block',
            user.role === 'DIRECTOR' || user.role === 'ZDIRECTOR'
              ? 'cursor-grab'
              : '',
            'yas' === theme.line ? 'pb-2 border-b-8 border-green-500' : '',
          ].join(' ')}
          ref={themeRef}
        >
          <span
            onClick={(e) => void toggleLine(e.type, e.button)}
            className="rounded-full bg-red-400 p-2 mr-2 text-white"
          >
            {numberList}
          </span>
          <div
            onClick={() => void fetchMaterials()}
            className={[
              'w-full rounded-full py-2 flex items-center justify-center font-bold text-sm border-2 ',
              active === theme._id
                ? 'bg-[#1EA413] border-[#1EA413] text-white'
                : 'border-[#C4C4C4] bg-white text-[#353535]',
            ].join(' ')}
          >
            {theme.name}
          </div>

          {user.role === 'DIRECTOR' || user.role === 'ZDIRECTOR' ? (
            <Menu
              as="div"
              className={
                'absolute right-2 top-1/2 -translate-y-1/2 z-10 arr-menu hidden'
              }
            >
              <Menu.Button className="text-gray-800 text-xl">
                <IoIosArrowDown />
              </Menu.Button>
              <Menu.Items
                as="div"
                className={`absolute -right-2 border bg-white w-[180px] rounded-2xl flex flex-col border-[#B7975A] overflow-hidden ${
                  shouldOpenUp ? '-translate-y-32 ' : ''
                }`}
              >
                <Menu.Item
                  as="button"
                  onClick={() => theme && editHandler(theme)}
                  className="border-b border-[#B7975A] py-1 font-medium hover:bg-[#B7975A] hover:text-white"
                >
                  Change studio
                </Menu.Item>
                <Menu.Item
                  as="button"
                  onClick={() => theme !== undefined && removeHandler(theme)}
                  className="border-b border-[#B7975A] py-1 font-medium hover:bg-[#B7975A] hover:text-white"
                >
                  Delete studio
                </Menu.Item>
                <Menu.Item
                  as="button"
                  className="py-1 font-medium hover:bg-[#B7975A] hover:text-white"
                  // onClick={openFirstModal}
                >
                  Comments
                </Menu.Item>
              </Menu.Items>
            </Menu>
          ) : (
            <div className="absolute right-2 top-1/2 z-10 -translate-y-1/2 arr-menu hidden">
              {/* <button onClick={openFirstModal}>
              <FaComment />
            </button> */}
            </div>
          )}
        </div>
        {/* <CommentFirstModal
        openModal={firstCommentModal}
        setOpenModal={setFirstCommentModal}
        groupId={theme._id}
        type={'STUDIO'}
      /> */}
      </Reorder.Item>
    );
  },
);

export default Theme;
