import { Link } from "react-router-dom";
import { useAppSelector } from "../../hooks/redux";
import Button from "../UI/Button";
import { UserRoles } from "../../utils/userRoles";
import { FC } from "react";
import classNames from "classnames";
import { translations } from "../../utils/translations";

interface TrustLessonWarningProps {
    className?: string;
    border?: boolean;
    payBtn?: boolean;
}

const TrustLessonWarning: FC<TrustLessonWarningProps> = ({ className, border = false,  payBtn = true }) => {
    const { user } = useAppSelector(state => state.UserSlice);
    const language = useAppSelector(state => state.TranslateSlice.language)
    const {
        trustLessonWarningText,
        payNowText,
    } = translations.access;
    if (user.role == UserRoles.STUDENT && user.trustLesson) {
        return (
            <div className={classNames("bg-[#f87168] p-2 flex flex-col sm:flex-row items-center justify-center gap-2", {'rounded-lg': border}, className)}>
                <p className='text-md sm:text-lg text-white text-center'>
                    { user.trustLessonDates && !!user.trustLessonDates.length  && `${new Date(user.trustLessonDates.at(-1) || '').toLocaleString()} - ` }
                    {trustLessonWarningText[language]}
                </p>
                {payBtn && (
                    <Link  to='/balance'>
                        <Button className='w-max mx-auto bg-sky-500 hover:text-white font-bold'>
                            {payNowText[language]}
                        </Button>
                    </Link>
                )}
            </div>
        )
    } else {
        return;
    }
}

export default TrustLessonWarning;